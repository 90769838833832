













































































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  setup(_, { root }) {
    const model = reactive({
      questions: [
        {
          polishName: null,
          englishName: null,
          polishDescription: null,
          englishDescription: null,
          ratings: [
            {
              polishDescription: null,
              englishDescription: null,
            },
          ],
          isVisible: true,
        },
      ],
    });

    const state = reactive({
      success: false,
      error: false as boolean | number,
      loading: false,
      loaded: false,
      formExist: [],
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("panel.event.abstract.config.erros.409")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchQuestions = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/question`)
        .then(({ data }) => {
          model.questions = data.map((q: any) => ({
            englishDescription: q.englishDescription,
            englishName: q.englishName,
            polishDescription: q.polishDescription,
            polishName: q.polishName,
            ratings: q.ratings
              .sort((a: any, b: any) => {
                return a.order - b.order;
              })
              .map((r: any) => ({
                polishDescription: r.polishDescription,
                englishDescription: r.englishDescription,
              })),
            isVisible: q.isVisible,
          }));
          state.formExist = data;
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            model.questions = [
              {
                polishName: null,
                englishName: null,
                polishDescription: null,
                englishDescription: null,
                ratings: [
                  {
                    polishDescription: null,
                    englishDescription: null,
                  },
                ],
                isVisible: true,
              },
            ];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchQuestions);

    const sendQuestions = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        questions: model.questions,
      };

      state.loading = true;

      if (state.formExist && state.formExist.length >= 1) {
        axiosInstance
          .put(`event/${root.$route.params.id}/question`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.error = false;
            state.success = true;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$tc(
                "panel.event.abstract.config.questions.updateSuccess"
              ),
            });
          })
          .catch((error) => {
            state.error = error.response?.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      } else {
        axiosInstance
          .post(`event/${root.$route.params.id}/question`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.error = false;
            state.success = true;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$tc(
                "panel.event.abstract.config.questions.addSuccess"
              ),
            });
          })
          .catch((error) => {
            state.error = error.response?.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      }
    };

    const addQuestion = () => {
      model.questions.push({
        polishName: null,
        englishName: null,
        polishDescription: null,
        englishDescription: null,
        ratings: [
          {
            polishDescription: null,
            englishDescription: null,
          },
        ],
        isVisible: true,
      });
    };

    const addRating = (index: any) => {
      model.questions[index].ratings.push({
        polishDescription: null,
        englishDescription: null,
      });
    };

    const removeQuestion = (index: number) => {
      model.questions.splice(index, 1);
    };

    const removeRating = (index: number, index2: number) => {
      model.questions[index].ratings.splice(index2, 1);
    };

    return {
      model,
      sendQuestions,
      addQuestion,
      addRating,
      removeQuestion,
      removeRating,
    };
  },
});
